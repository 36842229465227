//import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
// Admin pages
import Home from '../pages/index.vue'
//import store from '../store'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: Home,
  
  },
  { path: '*', component: NotFound }
]


export default new VueRouter({mode: 'history', routes, linkActiveClass: 'nav-item active'})
