<template>
    <div>
<div class="p-3 w-100" style="position: absolute;z-index:1003;top: 0; left: 0px;">
    <div class=" rounded-pill shadow-lg" style="background: #383838">
            
            <div class="d-flex justify-content-between align-items-center ">
              <div class="px-5">
               <!--<h4 class="text-light" style="padding: 0px;!important;font-family: 'Poppins', sans-serif;">
            <span style="color:#fff81a"> AQ </span>   Forecast
              </h4>-->
              <img src="img/airPP_logo_dark.png" style="width:10%" />

              </div>
           
            <div class="px-4 d-flex">
              
              
              <div id="mySidenav" class="sidenav">
                <a href="javascript:void(0)" class="closebtn" @click="closeNav">&times;</a>
                <a data-toggle="modal" data-target="#aboutModal" class="pointer">General Information</a>
                <hr class="w-100">
                <a data-toggle="modal" data-target="#PartnersModal" class="pointer">Specific information about Hamburg</a>
                <hr class="w-100">
                <a data-toggle="modal" data-target="#DisclaimerModal"  class="pointer">Disclaimer</a>
                <hr class="w-100">
                <a data-toggle="modal" data-target="#InformationModal"  class="pointer">Information about calculation of AQI index</a>
                <hr class="w-100">
                <a data-toggle="modal" data-target="#ContactModal" class="pointer">Contact</a>
              </div>
              <div class="col-md-12 d-flex justify-content-between pointer" >
              <!--<h2 class="d-flex align-items-center text-white" style="padding: 0px;!important">
               Teaser
              </h2>-->
              <a href="https://smurbs.eu/" target="_blank" style="width:60%"><img src="img/smurbs_logo_small.png" class="p-2"  style="width:100%" /></a>
              
              <span class="burger-menu text-light" align-v="center" @click="openNav">&#9776;</span>
              </div>
            </div>
            </div>
            
</div>
</div>

          <div class="modal fade" id="aboutModal" tabindex="-1" role="dialog" aria-labelledby="aboutModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="aboutModalLabel">General Information</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <p class="text-justify">
                      This application visualizes the air quality in Hamburg on a local scale for the current day. The data are
                      obtained by the CITYCHEM model which was developed at hereon. Based on different emission
                      sources like street traffic, ship emissions, producing factories and residential heating, an hourly
                      forecast for the next day is calculated for different air pollutants like NO, NO<sub> 2</sub>, O<sub>3</sub>, PM<sub>2.5</sub> and PM<sub>10</sub>
                      with a resolution of 100 m and summarized in the air quality index. 
                    </p>
                    <br>
                    <p class="text-justify">
                      For 21 monitoring stations throughout the city, the measured data for air pollutants can be attended
                      with one day delay. Also, the forecast for those stations is calculated. 
                    </p>
                </div>
              </div>
            </div>
          </div>
           <div class="modal fade" id="PartnersModal" tabindex="-1" role="dialog" aria-labelledby="PartnersModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="PartnersModaldalLabel">Partners</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p class="text-justify">
                    Hamburg is the second-largest city of Germany as well as one of the country's 16 constituent states.
                    The city is located in northern Germany with the North Sea to the west and the Baltic Sea to the
                    northeast. Hamburg borders the states of Schleswig-Holstein and Lower Saxony. Hamburg as major
                    port city is connected to the North Sea by the Elbe River (100km). It is crossed by hundreds of canals
                    and also contains large areas of parkland. The topography of Hamburg is rather flat, the highest point
                    has an altitude of about 115 m. The area of the city spans to 755.22 km<sup>2</sup>
                    . The City of Hamburg has a
                    population of about 1.82 million (2016) leading to a population density of roughly 2,400/km<sup>2</sup>
                    .
                  </p>
                  <br>
                  <p class="text-justify">
                    Air quality in Hamburg has improved continuously in the last two decades. However, exceedances of
                    the air quality regulatory limits for certain air pollutants occur occasionally. Road traffic and shipping
                    are major contributors to emissions of nitrogen oxides and fine particulates. Elevated car traffic with
                    the important side effect of enhanced emissions. High NO<sub>2</sub> concentration due to road traffic near
                    streets. Exceedance of annual NO<sub>2</sub>  limit values at all traffic monitoring sites. In winter, fine
                    particulate matter mainly from small residential heating pose a health problem. In 2011, the daily
                    mean of PM<sub>10</sub>  was exceeded (i.e. it was more than 35 times above 50 µg/m³).
                  </p>
                  <br>
                  <p class="text-justify">
                    Emissions of air pollutants from vehicular traffic, in particular diesel engines, contribute substantially
                    to the current burden in Hamburg, despite stringent European Union regulations on vehicle
                    emissions and increased efficiency of the engines. Main reason for the high local burden in street
                    canyons adding to the urban background can be attributed to the high number of diesel vehicles with
                    high NO<sub>X</sub>  emissions. Real-world emissions of diesel vehicles proofed to be several times higher than
                    emissions under engine test lab conditions.
                  </p>
               
                </div>
              </div>
            </div>
          </div>
           <div class="modal fade" id="DisclaimerModal" tabindex="-1" role="dialog" aria-labelledby="DisclaimerModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="DisclaimerModalLabel">Disclaimer</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                 <p class="text-justify">
                   The air quality forecast data is distributed in the hope that it will be useful. The accuracy of the data
                    from the forecast model is not guaranteed and may not be correlated enough with real
                    concentrations. The maps are only intended for informational purposes.
                 </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="InformationModal" tabindex="-1" role="dialog" aria-labelledby="InformationModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="InformationModalLabel">Information about calculation of AQI index</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                 <p class="text-justify">
                   The air quality index AQI is an hourly indicator for general air quality. The AQI value summarizes the
                    short-term effects of air pollutants on human health and allows to present them clearly. Five air
                    pollutant components are used to calculate the short-term air quality index. These pollutants are
                    considered to be representative components for the total exposure to air pollution and, at higher
                    exposure, they can cause acute complaints such as irritation of the respiratory tract or the eyes as
                    well as cardiovascular complaints in sensitive people.
                 </p>
                 <br>
                 <p class="text-justify">The following air pollutants are taken into account in this short-term air quality index:</p>
                
                   <li>
                     Nitrogen dioxide (NO<sub>2</sub>, 1-hour average)
                   </li>
                   <li>
                     Sulfur dioxide (SO<sub>2</sub> , 1-hour average)

                   </li>
                   <li>
                     Ozone (O<sub>3</sub> , 1-hour average)
                   </li>
                   <li>
                     Carbon monoxide (CO, 8-hour average)
                   </li>
                   <li>
                     Fine particulate matter (PM<sub>10</sub> , 24-hour average)
                   </li>
                 
                  <br>
                  <p class="text-justify">
                    The concentration values of these pollutants are divided into index classes in the AQI, which are
                    grouped according to the German school grading system with classes 1 (“very good”) to 6 (“very
                    bad”). The transition from class 4 (“sufficient”) to class 5 (“poor”) corresponds to exceeding the EUwide limit or information value for this pollutant.
                  </p>
                  <br>
                  <strong>AQI table</strong>
                  <br>
                  <p class="text-justify">
                    The AQI at any location results from the highest index class of any of these pollutants. The highest
                    AQI is always given as an indicator for the pollutant load at a certain location, which was determined
                    based on the modelled concentration from the pollutants at this location. The table below shows the
                    value ranges of the air pollutants for each AQI index class.
                  </p>
                  <table class="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">NO<sub>2</sub></th>
                        <th scope="col">SO<sub>2</sub></th>
                        <th scope="col">O<sub>3</sub></th>
                        <th scope="col">CO</th>
                        <th scope="col">PM<sub>10</sub></th>
                        <th scope="col">AQI</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1-hour average (µg / m³)</td>
                        <td>1-hour average (µg / m³)</td>
                        <td>1-hour average (µg / m³)</td>
                        <td>8-hour average (µg / m³)</td>
                        <td>24-hour average (µg / m³)</td>
                        <td>index value</td>
                      </tr>
                      <tr>
                        <td >0−25</td>
                        <td>0−25</td>
                        <td>0−33</td>
                        <td>0−1</td>
                        <td>0−10</td>
                        <th scope="row">1</th>
                      </tr>
                      <tr>
                        
                        <td >>25−50 </td>
                        <td>>25−50</td>
                        <td>>33−65</td>
                        <td>>1−2 </td>
                        <td>>10−20</td>
                        <th scope="row">2</th>
                      </tr>
                      <tr>
                        
                        <td>>50−100 </td>
                        <td>>50−100</td>
                        <td>>33−65</td>
                        <td>>65−120 </td>
                        <td>>20−35</td>
                        <th scope="row">3</th>
                      </tr>
                      <tr>
                        
                        <td >>100−200 </td>
                        <td>>120−350</td>
                        <td>>120−180</td>
                        <td>>4−10 </td>
                        <td>>35−50 </td>
                        <th scope="row">4</th>
                      </tr>
                      <tr>
                        
                        <td >>200−500  </td>
                        <td>>350−1000</td>
                        <td>>180−240</td>
                        <td>>10−30 </td>
                        <td>>50−100</td>
                        <th scope="row">5</th>
                      </tr>
                      <tr>
                        
                        <td>>500  </td>
                        <td>>1000</td>
                        <td>>240</td>
                        <td>>30 </td>
                        <td>>100</td>
                        <th scope="row">6</th>
                      </tr>
                    </tbody>
                  </table>
                  <p class="text-justify">
                    Example for reading the table:<br>
                    An AQI value of "2" for NO₂ (AQI-NO₂ = 2) means that the nitrogen dioxide concentration at this
                    location was on average between 25 and 50 µg / m³ over the last hour. An AQI value of “4” for PM<sub>10</sub> 
                    (AQI-PM<sub>10</sub>  = 4) means that the PM<sub>10</sub>  mean concentration over the last 24 hours at this location was
                    between 35 and 50 µg / m³. For a station with AQI-NO₂ = 2 and AQI-PM<sub>10</sub>  = 4, the AQI would be "4".
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div class="modal fade" id="ContactModal" tabindex="-1" role="dialog" aria-labelledby="ContactModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="ContactModalLabel">Contact</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                 <p class="text-justify">
                  We are happy to receive feedback from you if you have ideas of how to further develop or improve
                  this application. Please contact us under the following email address. 
                 </p>
                 
                 <!-- <p>Website design: <a href="https://www.noa.gr/">NOA</a></p>-->
                 <br>
                 <div class="row">
                 <div class="col-6">
                 <p>
                  <strong> Content:</strong><br> 
                  Helmholtz-Zentrum hereon<br>
                  Dr. Matthias Karl<br>
                  Chemistry Transport Modeling<br>
                  Max-Planck-Str. 1<br>
                  21502 Geesthacht, Germany<br>
                  E-mail:  <a href="mailto:matthias.karl@hereon.de"> matthias.karl@hereon.de</a>
                 </p>
                </div>
                <div class="col-6">
                  
                  <strong> Application Developer :</strong><br> 
                  Dimitris Vallianatos (<a href="https://idcom.gr/" target="_blank">IDCOM</a>)
                </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          

          <div v-if="!mobile" class="position-absolute p-2 text-light "  style="z-index:999;bottom: 10px; left: 0;background:rgb(56, 56, 56);width:400px;font-size: 12px;" >
            <p style="font-size: 17px;color:#0391a0">CITYAIRPP Implementation Team:</p>
            <strong>Dr. Matthias Karl</strong>, scientific data provider (Hereon, Geesthacht)<br>
            <strong>Sina Acksen</strong>, data assistant (Hereon, Geesthacht)<br>
            <strong>Dr. Eleni Athanasopoulou</strong>, Application scientific support (<a href="http://apcg.meteo.noa.gr/" target="_blank">APCG</a>, <a href="https://www.noa.gr/" target="_blank">NOA</a>)<br>
            <strong>Dimitris Vallianatos</strong>, Application Developer (<a href="https://idcom.gr/" target="_blank">IDCOM</a>)
          </div>
    </div>
</template>



<script>
export default {
  name:'TopIndex',
   

    data(){
      return{
        mobile:false
    }
    },
    computed: {
    
    },
 
methods:{

    closeNav(){
      document.getElementById("mySidenav").style.width = "0";
      
    },
    openNav(){
       document.getElementById("mySidenav").style.width = "250px";
      
    },
},
 
  mounted(){
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.mobile=true
    }
    
  },
    

}
</script>

<style>
h4{
    margin-bottom: 0rem!important;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1002;
  top: 0;
  right: 0;
  background-color: #090909;
  overflow-x: hidden;
 
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: rgb(238, 238, 238)!important;
  display: block;
  transition: 0.3s;
  font-weight: 500;
}

.sidenav a:hover {
  color: #fff81a!important;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
  
}
.sm-screen{
    display: block !important;
  }
.lg-screen{
    display: none !important;
}
@media only screen and (min-width: 1024px) {
  .sm-screen{
    display: none!important;
  }
  .lg-screen{
    display: block !important;
}
}

.burger-menu {
    font-size: 20px;
    cursor: pointer;
    color: rgb(29, 28, 28);
    padding: 0px 15px;
    margin: 9px 0px;
}
hr{
  border-top: 1px solid rgb(237 237 237)!important;
}
.pointer {cursor: pointer;text-decoration: none!important;}




</style>
