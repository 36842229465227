<template>
 <div>
     <div>
                  <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p v-html="title"> </p></div>
                    <div class="d-flex flex-row justify-content-center">
                    <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1" style="color:rgb(169,169,169);  height:1.5em!important" ></b-icon>
                      <div  class="d-flex flex-row px-1" >
                    
                  
                      <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #309afe;font-size: 14px;" >{{arrayValue[0]}}</div>
                      <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #63cffe;font-size: 14px;" >{{arrayValue[1]}}</div>
                      <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #9dffff;font-size: 14px;" >{{arrayValue[2]}}</div>
                      <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ffff9d;font-size: 14px;" >{{arrayValue[3]}}</div>
                      <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff9b3b;font-size: 14px;" >{{arrayValue[4]}}</div>
                      <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff2f32;font-size: 14px;" >{{arrayValue[5]}}</div>
                      
                      </div>
                      
                    <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1" style="color:rgb(170,0,0); height:1.5em!important "></b-icon>
                    </div>
                
                  <div class="text-center" style="font-size:15px;"><p v-html="subtitle"></p></div>
                </div>
 </div>
</template>

<script>

export default {
  props: ['title', 'arrayValue','subtitle'],
data(){
      return{}
}
}
</script>


