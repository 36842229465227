<template>
  <div style="background:gray">
  
  <TopBar></TopBar>
  
  <div class="" style="background-color:#c7c7c7" >
      <div class="">
          
          <div id="app" class="" >
            <div class="position-absolute" style="z-index:1000;top: 70px; left: 8px;">
              <div class="rounded-pill px-2 py-3 d-flex flex-column" style="background: #383838;height:180px;">
                <!--<b-button size="sm" variant="outline-light" class="py-3" @click="show_layer=!show_layer">
                    <b-icon icon="layers-fill" aria-hidden="true" :style="{color:colorIcon(show_layer)}"></b-icon>
                </b-button>-->
                <b-button size="sm" variant="outline-light" class="py-4" @click="show_play=!show_play">
                    <b-icon icon="clock-history" aria-hidden="true" :style="{color:colorIcon(show_play)}"></b-icon>
                </b-button>
                <b-button class="py-4" size="sm" variant="outline-light" @click="showCalendar">
                    <b-icon icon="bar-chart-fill" aria-hidden="true" :style="{color:colorIcon(show_calendar)}"></b-icon>
                 </b-button>
  
                 
              
              </div>
            </div>   
            <!--<div class="position-absolute" style="z-index:999;top: 100px; left: 60px;">
              <div>
              <transition name="fade">
                <div class=" d-flex align-items-center py-2 px-3 " style="background:rgb(72 72 72 / 62%);" v-if="show_layer">
                  <b-form-select  v-model="selected_grid" class="" size="sm" @change="ChangeSelectedGrid" >
                                  
                                    <template #first>
                                      <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                    </template>
                                  
                        <b-form-select-option value="PM25" html-field="html">PM2.5</b-form-select-option>
                        <b-form-select-option value="NO2" html-field="html">NO2</b-form-select-option>
                        <b-form-select-option value="AQI" html-field="html">AQI</b-form-select-option>
                        <b-form-select-option value="O3" html-field="html">O3</b-form-select-option>
                        <b-form-select-option value="O3H8" html-field="html">O3H8</b-form-select-option>
                        <b-form-select-option value="PM10" html-field="html">PM10</b-form-select-option>
                        
                       
                  </b-form-select>
                </div>
              </transition>
              </div>
            </div>-->
              <div class="position-absolute" style="z-index:999;top: 90px; left: 60px;width:95%" v-if="!mobile_div">
               <transition name="fade">
                <div class=" d-flex align-items-center p-2 " style="background:rgb(72 72 72 / 62%);" v-if="show_play">
                  <!--<b-form-select  v-model="selected_grid" size="sm" style="width:13%" @change="ChangeSelectedGrid" >
                                  
                                    <template #first>
                                      <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                    </template>
                                  
                        <b-form-select-option value="Pm25" html-field="html">PM <sub>2.5</sub></b-form-select-option>
                       
                  </b-form-select>-->
                  <b-form-select  v-model="selected_type" class="align-self-center" style="width:80px" size="sm" @change="ChangeSelectedType" >
                                  
                                    <template #first>
                                      <b-form-select-option :value="null" disabled>-- Select Type--</b-form-select-option>
                                    </template>
                                  
                        <b-form-select-option value="Hourly">Hourly</b-form-select-option>
                        <b-form-select-option value="Mean">Mean</b-form-select-option>
                       
                       
                      </b-form-select>
                    <div class="d-flex w-100">
                        <b-form-select  v-model="selected_grid" class="align-self-center" style="width:80px" size="sm" @change="ChangeSelectedGrid" >
                                  
                                    <template #first>
                                      <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                    </template>
                                  
                        <b-form-select-option value="PM25">PM2.5</b-form-select-option>
                        <b-form-select-option value="NO2" html-field="html">NO2</b-form-select-option>
                        <b-form-select-option v-if="selected_type==='Hourly'" value="AQI" html-field="html">AQI</b-form-select-option>
                        <b-form-select-option v-if="selected_type==='Hourly'" value="O3" html-field="html">O3</b-form-select-option>
                        <b-form-select-option v-if="selected_type==='Hourly'" value="O3H8" html-field="html">O3H8</b-form-select-option>
                        <b-form-select-option value="PM10" html-field="html">PM10</b-form-select-option>
                        
                       
                      </b-form-select>
                      <div class="d-flex w-100" v-if="selected_type==='Hourly'">
                       <vue-slider v-model="speedPlay" class="px-3" direction="btt" :min="1" :max='10' :tooltip-placement="'top'"
                       :tooltip-formatter="'speed:{value}'"></vue-slider>
                      <div class="px-1 " >
                          <b-form-checkbox size="sm"  button-variant="primary play-button" @change="play" v-model="checked1" name="check-button" button>
                          <div class="">
                          <b-icon v-if="!checked1" class="align-self-center" icon="play-circle-fill" aria-hidden="true"></b-icon>
                          <b-icon v-if="checked1" class="align-self-center" icon="pause-circle-fill" aria-hidden="true"></b-icon>
                          <p v-if="!checked1" class="px-2" style="margin-bottom: 0rem;">Play</p>
                          <p v-if="checked1" class="px-2" style="margin-bottom: 0rem;">Stop</p>
                          </div>
                          </b-form-checkbox>
                          <p class="text-light text-center" style="font-size:11px">{{dateSliderYMD}}</p>
                      </div>  
                        
                    <vue-slider class="mt-1 px-3" style="width:100%" v-model="value" :marks="marks" :max="max" :data="data"
                      @change="changeHour"
                      
                    >
                    </vue-slider>
                      </div>
                    
                    
                  </div>
                  
                
                </div>
              </transition>
              </div>
              <div class="position-absolute" style="z-index:999;top: 90px; left: 60px;" v-if="mobile_div ">
                <div >
              <transition name="fade">
                <div v-if="show_play" style="background:rgb(72 72 72 / 62%);" class="p-1">
                      <b-form-select  v-model="selected_type" class="mb-1" style="width:77px" size="sm" @change="ChangeSelectedType" >
                                  
                                    <template #first>
                                      <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                    </template>
                                  
                        <b-form-select-option value="Hourly">Hourly</b-form-select-option>
                        <b-form-select-option value="Mean">Mean</b-form-select-option>
                       
                       
                      </b-form-select>
                      <b-form-select  v-model="selected_grid" class="mb-1" style="width:77px" size="sm" @change="ChangeSelectedGrid" v-if='selected_type'>
                                  
                                    <template #first>
                                      <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                    </template>
                                  
                        <b-form-select-option value="PM25" html-field="html">PM2.5</b-form-select-option>
                        <b-form-select-option value="NO2" html-field="html">NO2</b-form-select-option>
                        <b-form-select-option value="AQI" html-field="html">AQI</b-form-select-option>
                        <b-form-select-option value="O3" html-field="html">O3</b-form-select-option>
                        <b-form-select-option value="O3H8" html-field="html">O3H8</b-form-select-option>
                        <b-form-select-option value="PM10" html-field="html">PM10</b-form-select-option>
                        
                       
                      </b-form-select>
                      <div class="text-center">
                        
                            
                            <b-form-checkbox size="sm" button-variant="primary play-button" @change="play" v-model="checked1" name="check-button" button>
                            <div class="">
                            <b-icon v-if="!checked1" class="align-self-center" icon="play-circle-fill" aria-hidden="true"></b-icon>
                            <b-icon v-if="checked1" class="align-self-center" icon="pause-circle-fill" aria-hidden="true"></b-icon>
                            <p v-if="!checked1" class="px-2" style="margin-bottom: 0rem;">Play</p>
                            <p v-if="checked1" class="px-2" style="margin-bottom: 0rem;">Stop</p>
                            </div>
                            </b-form-checkbox>
                          </div> 
                            <p class="text-light text-center" style="font-size:11px">{{dateSliderYMD}}</p>
                            <div class="" style="width:77px">
                              <scroll-picker :options="optionsPicker"  v-model="value" @input="changeHour"/>
                            </div>
                        
  
                </div>
              </transition>
                </div>
              </div>
              <div class="position-absolute" style="z-index:999;top: 175px; left: 60px;" v-if="!mobile_div">
              <transition name="fade">
                <div class=" p-2 " style="background:rgb(72 72 72 / 62%);" v-if="show_calendar ">
                  <div class="d-flex align-items-center">
                  <b-form-select  v-model="selected_grid_chart" class="" style="width:80px" size="sm" @change="ChangeSelectedGridChart" >
                                  
                                    <template #first>
                                      <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                    </template>
                                  
                        <b-form-select-option value="no" html-field="html">NO</b-form-select-option>
                        <b-form-select-option value="pm25" html-field="html">PM2.5</b-form-select-option>
                        <b-form-select-option value="no2" html-field="html">NO2</b-form-select-option>
                        <b-form-select-option value="aqi_index" html-field="html">AQI</b-form-select-option>
                        <b-form-select-option value="o3" html-field="html">O3</b-form-select-option>
                        <b-form-select-option value="so2" html-field="html">SO2</b-form-select-option>
                        <b-form-select-option value="pm10" html-field="html">PM10</b-form-select-option>
                        <b-form-select-option value="co" html-field="html">CO</b-form-select-option>
                        
                       
                  </b-form-select>
                  <div class="d-flex justify-content-center ml-2">
                  <v-date-picker :columns="$screens({ lg: 2 },1)" :available-dates='availableDates' :value="inputValue" class="w-100" @dayclick="ApplyCalentar" :masks="masks">
                     <template v-slot="{ inputValue, togglePopover }">
                      <div class="d-flex justify-content-center ">
                        <b-button
                          class="w-25"
                          variant="warning"
                          @click="togglePopover()"
                        >
                        <b-icon class="align-self-center" icon="calendar2-date-fill" aria-hidden="true"></b-icon>          
                        </b-button>
                        <input
                          :value="inputValue"
                          class="bg-white text-gray-700 w-100 py-1 px-2 border rounded"
                          readonly
                        />
                      </div>
                    </template>
  
                  </v-date-picker>
                  
                  </div>
                </div>
                  <div class="mt-2">
                
                    <div class="d-flex justify-content-center">
                      <div class="w-100" style="max-width:600px" @mouseover="ItemMouseOver" @mouseout="ItemMouseLeave">
                        
                        <multi-select v-model="placeArray" :internal-search="false" placeholder="Add a Place" :options="place_name" :multiple="true" :searchable="false" :show-no-results="false" :hide-selected="true" @select="dispatchAction" @remove="removeItem" 
                        >
                          
                        </multi-select>
                        
                      </div>
                    </div>
                    <div  style="width:600px" class="mt-2" v-if="show_chart">
                      <vue-highcharts :options="options" ref="lineCharts"></vue-highcharts>
                      <div class="d-flex justify-content-center text-center">
                         
                        <div class="text-white px-2" style="width:150px">
                          <hr style="border-top: 1px solid white!important;margin-bottom: 0rem!important;">
                          Prediction
                        </div>
                        <div class="text-white  px-2" style="width:150px">
                          <hr style="border-top: 1px dashed white!important;margin-bottom: 0rem!important;">
                          Measurements
                        </div>
                      </div> 
                      <div class="text-center mt-3" style="color:#0793a1">
                        *Measurements values are available for NO, NO<sub>2</sub>, O<sub>3</sub> and PM<sub>10</sub>
                      </div>
                    </div>
                    
                 <!--<b-tabs content-class="p-1">
                   <b-tab title="Model" active>
                      <div  style="width:500px" class="mt-2" v-if="show_chart">
                        <vue-highcharts :options="optionsModel" ref="lineChartsModel"></vue-highcharts>
                      </div>
  
                    </b-tab>
                    
                    <b-tab title="Comparison" >
                      <div class="d-flex justify-content-center">
                      <div class="w-100" style="max-width:500px" @mouseover="ItemMouseOver" @mouseout="ItemMouseLeave">
                        
                        <multi-select v-model="placeArray" :internal-search="false" placeholder="Add a Place" :options="place_name" :multiple="true" :searchable="false" :show-no-results="false" :hide-selected="true" @select="dispatchAction" @remove="removeItem" 
                        >
                          
                        </multi-select>
                        
                      </div>
                    </div>
                    <div  style="width:500px" class="mt-2" v-if="show_chart">
                        <vue-highcharts :options="options" ref="lineCharts"></vue-highcharts>
                    </div>
  
                    </b-tab>
                    
                  </b-tabs>-->
                  </div>
                    
                  
                
                </div>
                
              </transition>
              </div>
            
            <!--<div class="position-absolute" style="z-index:999;top: 100px; left: 60px;width:90%">
              <transition name="fade">
                <div class=" d-flex align-items-center p-2 " style="background:rgb(72 72 72 / 62%);" v-if="show_play">
                  <b-form-select  v-model="selected_grid" size="sm" style="width:13%" @change="ChangeSelectedGrid" >
                                  
                                    <template #first>
                                      <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                    </template>
                                  
                        <b-form-select-option value="Pm25" html-field="html">PM <sub>2.5</sub></b-form-select-option>
                       
                  </b-form-select>
                    <div class="d-flex p-2 w-100" >
                       
                      <div class="px-2">
                          <b-form-checkbox size="sm"  button-variant="primary play-button" @change="play" v-model="checked1" name="check-button" button>
                          <div class="">
                          <b-icon v-if="!checked1" class="align-self-center" icon="play-circle-fill" aria-hidden="true"></b-icon>
                          <b-icon v-if="checked1" class="align-self-center" icon="pause-circle-fill" aria-hidden="true"></b-icon>
                          <p v-if="!checked1" class="px-2" style="margin-bottom: 0rem;">Play</p>
                          <p v-if="checked1" class="px-2" style="margin-bottom: 0rem;">Stop</p>
                          </div>
                          </b-form-checkbox>
                      </div>    
                    <vue-slider class="mt-3 px-3" style="width:100%" v-model="value" :marks="marks" :max="max" :data="data"
                      @change="changeHour"
                      
                    >
                    </vue-slider>
                    
                  </div>
                
                </div>
              </transition>
            </div>-->
             
          <l-map :zoom="zoom" @update:zoom="zoomUpdated" :center="center"  
          @update:center="centerUpdated"  ref="myMap" :options="{zoomControl: false,attributionControl: false}" :style="{height:map_height}"
           style="transition: 0.5s;">
            
  
              <l-tile-layer :url="url"></l-tile-layer>
              <!--<l-wms-tile-layer  ref="wms_layer" 
                       

                       :base-url="baseUrl"
                       :layers="layers[0].layers"
                       :visible="layers[0].visible"
                       :name="layers[0].name"
                       :format="layers[0].format"
                       :transparent="true"
                       
                       :crs="layers[0].crs"
                      >
                       
               </l-wms-tile-layer>-->
               <l-layer-group ref="features">
              <l-popup > <span> {{output_of_box}} </span></l-popup>
            </l-layer-group>
    
              <l-control position="bottomright">
                
                <div  class="p-2 text-light" style="background-color: #383838">
                  <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p>Layer opacity:</p></div>
                  <vue-slider v-model="valueOpacity" class="p-2"></vue-slider>
                  <hr style="margin-top: 0.2rem;margin-bottom: 0.2rem">
                  <!--<div v-if="selected_grid==='PM25'">
                  <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p>Particulate matter (PM<sub>2.5</sub>)</p></div>
                    <div class="d-flex flex-row justify-content-center">
                    <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1" style="color:rgb(169,169,169);  height:1.5em!important" ></b-icon>
                      <div  class="d-flex flex-row px-1" >
                    
                      <div class="box2 d-flex align-items-center justify-content-center" style="background-color: rgb(169,169,169);font-size: 14px;">invalid</div>
                      <div class="box2 d-flex align-items-center justify-content-center" style="background-color: rgb(0,85,0);font-size: 14px;" >0-10</div>
                      <div class="box2 d-flex align-items-center justify-content-center" style="background-color: rgb(0,170,0);font-size: 14px;" >11-20</div>
                      <div class="box2 d-flex align-items-center justify-content-center" style="background-color: rgb(255,170,0);font-size: 14px;" >22-25</div>
                      <div class="box2 d-flex align-items-center justify-content-center" style="background-color: rgb(255,85,0);font-size: 14px;" >26-50</div>
                      <div class="box2 d-flex align-items-center justify-content-center" style="background-color: rgb(170,0,0);font-size: 14px;" >>50</div>
                      
                      </div>
                      
                    <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1" style="color:rgb(170,0,0); height:1.5em!important "></b-icon>
                    </div>
                
                  <div class="text-center" style="font-size:15px;"><p>(μg*m<sup>-3</sup>)</p></div>
                  </div>-->
                  <Legend :title='selectedLegend.title' :arrayValue="selectedLegend.data" :subtitle='selectedLegend.subtitle'></Legend>
                <!--  <div v-if="selected_grid==='PM25'">
                    <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p>Particulate matter (PM<sub>2.5</sub>)</p></div>
                      <div class="d-flex flex-row justify-content-center">
                      <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1" style="color:rgb(169,169,169);  height:1.5em!important" ></b-icon>
                        <div  class="d-flex flex-row px-1" >
                      
                    
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #309afe;font-size: 14px;" >0-10</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #63cffe;font-size: 14px;" >11-20</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #9dffff;font-size: 14px;" >21-25</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ffff9d;font-size: 14px;" >26-50</div>
                      
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff2f32;font-size: 14px;" >>50</div>
                        
                        </div>
                        
                      <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1" style="color:rgb(170,0,0); height:1.5em!important "></b-icon>
                      </div>
                  
                    <div class="text-center" style="font-size:15px;"><p>(μg*m<sup>-3</sup>)</p></div>
                  </div>
                  <div v-if="selected_grid==='NO2'">
                    <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p> NO<sub>2</sub></p></div>
                      <div class="d-flex flex-row justify-content-center">
                      <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1" style="color:rgb(169,169,169);  height:1.5em!important" ></b-icon>
                        <div  class="d-flex flex-row px-1" >
                      
                    
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #309afe;font-size: 14px;" >0-25</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #63cffe;font-size: 14px;" >26-50</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #9dffff;font-size: 14px;" >51-100</div>
                 
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff2f32;font-size: 14px;" >>100</div>
                        
                        </div>
                        
                      <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1" style="color:rgb(170,0,0); height:1.5em!important "></b-icon>
                      </div>
                  
                    <div class="text-center" style="font-size:15px;"><p>(μg*m<sup>-3</sup>)</p></div>
                  </div>
                  <div v-if="selected_grid==='O3'">
                    <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p> O<sub>3</sub></p></div>
                      <div class="d-flex flex-row justify-content-center">
                      <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1" style="color:rgb(169,169,169);  height:1.5em!important" ></b-icon>
                        <div  class="d-flex flex-row px-1" >
                      
                    
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #309afe;font-size: 14px;" >0-33</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #63cffe;font-size: 14px;" >33-65</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #9dffff;font-size: 14px;" >66-120</div>
                      
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff2f32;font-size: 14px;" >>120</div>
                        
                        </div>
                        
                      <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1" style="color:rgb(170,0,0); height:1.5em!important "></b-icon>
                      </div>
                  
                    <div class="text-center" style="font-size:15px;"><p>(μg*m<sup>-3</sup>)</p></div>
                  </div>
                   <div v-if="selected_grid==='SO2'">
                    <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p> NO<sub>2</sub></p></div>
                      <div class="d-flex flex-row justify-content-center">
                      <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1" style="color:rgb(169,169,169);  height:1.5em!important" ></b-icon>
                        <div  class="d-flex flex-row px-1" >
                      
                    
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #309afe;font-size: 14px;" >0-25</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #63cffe;font-size: 14px;" >26-50</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #9dffff;font-size: 14px;" >51-120</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ffff9d;font-size: 14px;" >121-350</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff9b3b;font-size: 14px;" >351-1000</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff2f32;font-size: 14px;" >>1000</div>
                        
                        </div>
                        
                      <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1" style="color:rgb(170,0,0); height:1.5em!important "></b-icon>
                      </div>
                  
                    <div class="text-center" style="font-size:15px;"><p>(μg*m<sup>-3</sup>)</p></div>
                  </div>
                  <div v-if="selected_grid==='SO2'">
                    <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p> NO<sub>2</sub></p></div>
                      <div class="d-flex flex-row justify-content-center">
                      <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1" style="color:rgb(169,169,169);  height:1.5em!important" ></b-icon>
                        <div  class="d-flex flex-row px-1" >
                      
                    
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #309afe;font-size: 14px;" >0-25</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #63cffe;font-size: 14px;" >26-50</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #9dffff;font-size: 14px;" >51-120</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ffff9d;font-size: 14px;" >121-350</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff9b3b;font-size: 14px;" >351-1000</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff2f32;font-size: 14px;" >>1000</div>
                        
                        </div>
                        
                      <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1" style="color:rgb(170,0,0); height:1.5em!important "></b-icon>
                      </div>
                  
                    <div class="text-center" style="font-size:15px;"><p>(μg*m<sup>-3</sup>)</p></div>
                  </div>
                   <div v-if="selected_grid==='CO'">
                    <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p> CO</p></div>
                      <div class="d-flex flex-row justify-content-center">
                      <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1" style="color:rgb(169,169,169);  height:1.5em!important" ></b-icon>
                        <div  class="d-flex flex-row px-1" >
                      
                    
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #309afe;font-size: 14px;" >0-1</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #63cffe;font-size: 14px;" >2</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #9dffff;font-size: 14px;" >3-4</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ffff9d;font-size: 14px;" >5-10</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff9b3b;font-size: 14px;" >11-30</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff2f32;font-size: 14px;" >>30</div>
                        
                        </div>
                        
                      <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1" style="color:rgb(170,0,0); height:1.5em!important "></b-icon>
                      </div>
                  
                    <div class="text-center" style="font-size:15px;"><p>(mg*m<sup>-3</sup>)</p></div>
                  </div>
                  <div v-if="selected_grid==='PM10'">
                    <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p> PM<sub>10</sub></p></div>
                      <div class="d-flex flex-row justify-content-center">
                      <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1" style="color:rgb(169,169,169);  height:1.5em!important" ></b-icon>
                        <div  class="d-flex flex-row px-1" >
                      
                    
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #309afe;font-size: 14px;" >0-10</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #63cffe;font-size: 14px;" >11-20</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #9dffff;font-size: 14px;" >21-35</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ffff9d;font-size: 14px;" >36-50</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff9b3b;font-size: 14px;" >51-100</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff2f32;font-size: 14px;" >>100</div>
                        
                        </div>
                        
                      <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1" style="color:rgb(170,0,0); height:1.5em!important "></b-icon>
                      </div>
                  
                    <div class="text-center" style="font-size:15px;"><p>(μg*m<sup>-3</sup>)</p></div>
                  </div>
                  <div v-if="selected_grid==='AQI'">
                    <div class="text-center fw-bold" style="font-size:18px;font-weight: bold;"><p> AQI</p></div>
                      <div class="d-flex flex-row justify-content-center">
                      <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1" style="color:rgb(169,169,169);  height:1.5em!important" ></b-icon>
                        <div  class="d-flex flex-row px-1" >
                      
                    
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #309afe;font-size: 14px;" >1</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #63cffe;font-size: 14px;" >2</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #9dffff;font-size: 14px;" >3</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ffff9d;font-size: 14px;" >4</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff9b3b;font-size: 14px;" >5</div>
                        <div class="box2 text-dark d-flex align-items-center justify-content-center" style="background-color: #ff2f32;font-size: 14px;" >6</div>
                        
                        </div>
                        
                      <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1" style="color:rgb(170,0,0); height:1.5em!important "></b-icon>
                      </div>
                  
                  
                  </div>-->
                  
                </div>
              </l-control>
              <l-control-scale position="bottomright" :imperial="true" :metric="true"></l-control-scale>
              
               
                <div v-if="urlImg">
                  <l-image-overlay :url="urlImg" :bounds="bounds" :opacity="valueOpacity/100"></l-image-overlay>
                </div>
  
  
           <div v-for="(marker,index) in marker_countries" :key='index+"100"'>
                <l-marker :lat-lng="[marker.geometry.coordinates[1],marker.geometry.coordinates[0]]" ref="myMarker"> 
                  <l-icon v-if="!placeArray.includes(marker.properties.name)&& modelChartMarker.properties.name!==marker.properties.name"
                  :icon-size="[25, 25]"
                  icon-url="/markers/iconsorange.png"
                    />
                  <l-icon v-if="placeArray.includes(marker.properties.name)&& modelChartMarker.properties.name!==marker.properties.name"
                  :icon-size="[25, 25]"
                  icon-url="/markers/iconsgreen.png"
                    />
                  <l-icon v-if="modelChartMarker.properties.name===marker.properties.name"
                  :icon-size="[25, 25]"
                  icon-url="/markers/pin.png"
                    />
                    
                  <l-popup > 
                    <div class="">
                    <b-button size="sm" variant="primary" @click="OpenChart(marker)">
                      Open a single chart<!--Add to model-->
                    </b-button>
                    <div class="text-center">-OR-</div>
                    <b-button size="sm"  variant="success" @click="AddToChart(marker)" :disabled="placeArray.includes(marker.properties.name)">
                      Add to chart
                    </b-button>
                    </div>
                  </l-popup>
                <l-tooltip>{{marker.properties.name}}</l-tooltip>
                </l-marker>
                </div>
                
           </l-map>
            
         
        
          </div>
  
             
      </div>
   
    
  </div>
    <div v-if="mobile_div">
                  
                  <div v-if="show_calendar" style="background-color: rgba(208, 208, 208,0.9);">
                    
                  <button @click="hideChart" type="button" class=" btn btn-sm" style="background:#fefe00">
                    Close
                  </button>
            
                
                   
                  <div class=" p-2 " style="background:rgb(72 72 72 / 62%);" v-show="show_calendar">
                  <div class="d-flex justify-content-center py-2">
                    <div class="w-100" style="max-width:500px;z-index:1002;" @mouseover="ItemMouseOver" @mouseout="ItemMouseLeave">
                      
                      <multi-select v-model="placeArray" :internal-search="false" placeholder="Add a Place" :options="place_name" :multiple="true" :searchable="false" :show-no-results="false" :hide-selected="true" @select="dispatchAction" @remove="removeItem"
                      >
                        
                      </multi-select>
                      
                    </div>
                  </div>
                   <b-form-select  v-model="selected_grid_chart" class="" size="sm" @change="ChangeSelectedGridChart" >
                                  
                                    <template #first>
                                      <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                    </template>
                                  
                        <b-form-select-option value="no" html-field="html">NO</b-form-select-option>
                        <b-form-select-option value="pm25" html-field="html">PM2.5</b-form-select-option>
                        <b-form-select-option value="no2" html-field="html">NO2</b-form-select-option>
                        <b-form-select-option value="aqi_index" html-field="html">AQI</b-form-select-option>
                        <b-form-select-option value="o3" html-field="html">O3</b-form-select-option>
                        <b-form-select-option value="so2" html-field="html">SO2</b-form-select-option>
                        <b-form-select-option value="pm10" html-field="html">PM10</b-form-select-option>
                        <b-form-select-option value="co" html-field="html">CO</b-form-select-option>
                        
                       
                  </b-form-select>
                  <div class="d-flex justify-content-center">
                  <v-date-picker :columns="$screens({ lg: 2 },1)" :value="inputValue" class="w-100" style="z-index:1001"
                  @dayclick="ApplyCalentar"
                  :available-dates='availableDates'>
                     <template v-slot="{ inputValue, togglePopover }">
                      <div class="d-flex justify-content-center ">
                        <b-button
                          class=""
                          variant="warning"
                          @click="togglePopover()"
                        >
                        <b-icon class="align-self-center" icon="calendar2-date-fill" aria-hidden="true"></b-icon>          
                        </b-button>
                        <input
                          :value="inputValue"
                          class="bg-white text-gray-700 w-75 py-1 px-2 border rounded"
                          readonly
                        />
                      </div>
                    </template>
  
                  </v-date-picker>
                  </div>
                  
                  <div  class="mt-2 w-100" v-if="show_chart">
                    <vue-highcharts :options="options" ref="lineCharts"></vue-highcharts>
                    <div class="d-flex justify-content-center text-center">
                    <div class="text-white px-2" style="width:150px">
                      <hr style="border-top: 1px solid white!important;margin-bottom: 0rem!important;">
                      Prediction
                    </div>
                     <div class="text-white  px-2" style="width:150px">
                      <hr style="border-top: 1px dashed white!important;margin-bottom: 0rem!important;">
                      Measurements
                    </div>
                    </div> 
                 </div>
                
                </div>
                
                  
                  </div>
                   
    </div>
  </div>
  
  
  </template>
  
  <script>
  import L from 'leaflet';
  import { CRS, latLng } from "leaflet";
  import DatePicker from 'v-calendar/lib/components/date-picker.umd'
  import axios from 'axios'
  import TopBar from './Sidenav.vue'
  import { LMap, 
  //LTileLayer, 
  //LMarker,
  //LGeoJson,
  LControl,
  LTooltip,
  LControlScale,
  //LWMSTileLayer,
  LPopup,
  LLayerGroup,
  LIcon,
  LImageOverlay  
  } from 'vue2-leaflet';
  import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'
  import 'vue-slider-component/dist-css/vue-slider-component.css'
  
  import 'vue-slider-component/theme/default.css'
  //import Vue2LeafletHeatmap from './Vue2LeafletHeatmap.vue'
  //import { Control } from 'leaflet';
  //import VueHighcharts from 'vue2-highcharts'
  //import json_data from '../../public/json/markers.json'
  import stations from '../../public/json/station_32632_1.json'
  import Legend from '../components/legendL.vue'
  import legend_data from '../../public/json/legend_data.json'
  var loopPLay;
  
  
  export default {
    name:'FirstIndex',
      components:{
        Legend,
        LImageOverlay,
        LLayerGroup,
          LPopup,
          LControlScale,
          LTooltip,
          LControl,
  
          TopBar,
          LMap,
          //LTileLayer,
          //LMarker,
          VueSlider,
          //LGeoJson,
          //'l-wms-tile-layer': LWMSTileLayer,
          'v-date-picker':DatePicker,
          //VueHighcharts,
          LIcon ,
          
         
      },
  //top right [53.682617226,25.240024623]
  //bottom left [53.416830125,24.780881585]
  //bottom right  [53.413026609,25.232168527] [47.040308933,5.225949165]
  //top left  [53.686458145,24.785861281]  [46.856336635,4.95645458]  
  /* <b-form-select-option value="PM25" html-field="html">PM2.5</b-form-select-option>
                        <b-form-select-option value="NO2" html-field="html">NO2</b-form-select-option>
                        <b-form-select-option value="AQI" html-field="html">AQI</b-form-select-option>
                        <b-form-select-option value="O3" html-field="html">O3</b-form-select-option>
                        <b-form-select-option value="O3H8" html-field="html">O3H8</b-form-select-option>
                        <b-form-select-option value="PM10" html-field="html">PM10</b-form-select-option>*/
      data(){
        return{
          selected_type:'Hourly',
          modelChartMarker:{properties:{name:''}},
          availableDates:[],
          legendData:legend_data,
          selectedLegend:null,
          all_data_predictions:null,
          all_data_measures:null,
          date_slider:null,
          dateSliderYMD:null,
          masks: {
              input: 'DD/MM/YYYY',
          },
          //bounds:[[53.682617226,9.77950],[53.414430125,10.238175]],
          bounds:[[53.41442632802025514,9.77860045208070616],[53.68022692119712502,10.23771102108161912]],
          urlImg: null,
          speedPlay:5,
          placeArray:[],
          selected_place:null,
          //data_marker:json_data,
          mobile_div:false,
          data1:5,
          optionsPicker:[],
          show_chart:false,
          inputValue:new Date(),
       
        show_calendar:false,
        show_layer:true,
        selected_grid:null,
        selected_grid_chart:'no',
        show_play:true,
        valueOpacity:70,
        color: 'red',
        checked1:false,
        value: '0',
        data:[],
        marks:{},
        output_of_box:null,
        baseUrl: 'http://idcomvps01.ddns.net:8080/geoserver/gauss/wms',
        map_height:'100vh',
        url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
        
        zoom: 10,
        center: [53.55458652225702, 10.003738403320314],
        max:1440,
        layers: [
          {
            visible: true,
            format: 'image/png',
            layers: 'gauss:no2_202001',
            transparent: true,
            crs:L.CRS.EPSG4326
          }
        ],
          optionsModel: {
           
            chart: {
              backgroundColor: 'rgba(208, 208, 208, 0)',
              
              type: 'spline'
            },
            plotOptions: {
                series: {
                    shadow: true
                }
            },
            legend: {
              style: {
                color: '#efefef'
              },
              itemStyle: {
                color: '#efefef'
              },
              itemHoverStyle: {
                color: 'grey'
              }
            },
            credits: {
                enabled: false
            },
            title: {
              text: 'Predictions-Measurements',
              style: {
                color: '#efefef'
                },
            }, 
            subtitle:{
              text:'new'
            },
            xAxis: {
        
              labels: {
                style: {
                  color: '#efefef'
                }
              }
            },
           yAxis: {
            min:0,
        title: {
          style: {
            color: '#efefef'
          }
        },
        labels: {
          style: {
            color: '#efefef'
          }
        },
      /*  plotLines: [{
          color: 'red',
          width: 2,
          value: 120,
          zIndex: 5
      }]*/
      },
      
            
           series: [
             
           ]
        },
        options: {
           
            chart: {
              backgroundColor: 'rgba(208, 208, 208, 0)',
              
              type: 'spline'
            },
            
            plotOptions: {
                series: {
                    shadow: true
                }
            },
            legend: {
              style: {
                color: '#efefef'
              },
              itemStyle: {
                color: '#efefef'
              },
              itemHoverStyle: {
                color: 'grey'
              }
            },
            credits: {
                enabled: false
            },
            title: {
              text: '',
              style: {
                color: '#efefef'
                },
            }, 
            subtitle:{
             // text:'new'
            },
            xAxis: {
              title: {
              text: 'hours in UTC',
              style: {
                  color: '#efefef'
                }
              },
              labels: {
               
                style: {
                  color: '#efefef'
                }
              }
            },
           yAxis: {
            min:0,
            
        title: {
          text: 'concentrations ( μg*m-3 )',
          style: {
            color: '#efefef'
          }
        },
        labels: {
          style: {
            color: '#efefef'
          }
        },
      /*  plotLines: [{
          color: 'red',
          width: 2,
          value: 120,
          zIndex: 5
      }]*/
      },
      
            
           series: [
             
           ]
        },
          
          //place_name:['altona-elbhang','billbrook','billstedt','blankenese-baursberg','bramfeld','finkenwerder-airbus','finkenwerder-west','flughafen-nord-01','georgswerder','habichtstrasse','heimfeld','kieler-strasse','max-brauer-allee-ii','neugraben','simon-von-utrecht-strasse-ii','sternschanze','stresemannstrasse','tatenberg','veddel','wilhelmsburg','hafen\/kleiner-grasbrook'],
          place_name:[],
          place_data_stations:[],
          marker_countries:stations.features
          /*[{
            center:[ 53.56641415275043, 10.019531250000002],
            name:'place1'
          },
          {
            center:[53.548467484689226, 9.858306851238012],
            name:'place2'
          },
          {
            center:[53.526268415903104,10.01870730891824],
            name:'place3'
          },
          {
            center:[53.54716197051158, 10.12637327425182],
            name:'place4'
          }
          ]*/,
        
  
        
      }
      },
      computed: {
      
      },
   created(){
      if(window,innerWidth<1024){
        this.mobile_div=true
      }
      this.slider()
    var v=this
    axios.get('https://cityairpp.net/backend/datesnc.php')
    //.then(response => response.text())
    .then(data => {
      console.log(data)
      v.selected_grid='PM25'
      let date_nc=data.data[0].max.split('-')
      date_nc=date_nc[2]+'-'+date_nc[1]+'-'+date_nc[0]
      v.date_slider=date_nc
      v.dateSliderYMD=date_nc
      v.urlImg='./dataPNG/'+v.selected_grid+'/'+v.date_slider+'_0.png'
    })
  
  /*
    const illustrations = require.context(
    '../../public/dataPNG/PM25/',//dataPNG',
    true,
    /^.*\.png$/
    )
    //////console.log(illustrations.keys())
    
    var date_data=illustrations.keys()
    //////console.log(typeof(date_data[0]))
    var re=date_data[0].split('./')
    var date=re[1].split('_')
    //////console.log(date)
    var w=date[0]
  
    ////console.log(w)
    ////console.log(date[0])
    this.date_slider=w
    var date_now=w[0]+w[1]+w[2]+w[3]+'-'+w[4]+w[5]+'-'+w[6]+w[7]
    //////console.log(date_now)
    //this.inputValue=date_now
    this.dateSliderYMD=date_now
    
    //console.log(this.date_slider)
    //console.log(this.dateSliderYMD)
  
    this.selected_grid='PM25'
    this.urlImg='./dataPNG/'+this.selected_grid+'/'+this.date_slider+'_1.png'
    */
    
    var ob=stations.features
    for(var rr=0; rr<ob.length; rr++){
      this.place_name.push(ob[rr].properties.name)
      this.place_data_stations.push({name:ob[rr].properties.name,id:ob[rr].properties.id})
    }
  
    this.selectedLegend= this.legendData['PM25']
    
    var v=this
    axios.get('https://cityairpp.net/backend/dates.php').then((response)=>{
      
      for(var rr=0; rr<response.data.length; rr++){
        v.availableDates.push({start:response.data[rr].measdate,end:response.data[rr].measdate})
      }
      //console.log(v.availableDates)
      v.inputValue=response.data[0].measdate
      v.getStationData()
    })
  
    //this.availableDates=[{start:null,end:new Date()}]
    },
  methods:{
    ApplyCalentar(day){
      //////console.log(day.id)
      //////console.log(this.inputValue)
      this.inputValue=day.id
      this.getStationData()
      
    },
    ItemMouseLeave(item){
      //console.log('leave',item.target.className)
      //console.log('leave',item.relatedTarget.className)
      //console.log('leave',!item.target.className.includes("multiselect__tag"))
      
      if(!item.target.className.includes("multiselect__tag") && !item.relatedTarget.className.includes("multiselect__tag")){
       // ////console.log(item.target)
        //////console.log(item.target.innerText)
      var myMarker=this.$refs.myMarker
      //////console.log(myMarker[0].mapObject)
      var num=this.place_name.indexOf(item.target.innerText)
      //////console.log(num)
      if(myMarker[num]){
        myMarker[num].mapObject.setIcon(
              L.icon({
                iconUrl: '/markers/iconsorange.png',
                iconSize:[25, 25],
              }))
      }
      
     }
    },
    ItemMouseOver(item){
      ////console.log('over',item.relatedTarget.className)
      if(item.target.className=== "multiselect__option" || item.target.className=== "multiselect__option multiselect__option--highlight" || item.relatedTarget.className=== "multiselect__option multiselect__option--highlight"){//multiselect__tags
      var myMarker=this.$refs.myMarker
      //////console.log(myMarker[0].mapObject)
      var num=this.place_name.indexOf(item.target.innerText)
      //////console.log(num)
      if(myMarker[num]){
  
      
      myMarker[num].mapObject.setIcon(
              L.icon({
                iconUrl: '/markers/iconspurple.png',
                iconSize:[25, 25],
              }))
      }
        //////console.log(item.target.innerText)
      }
  
    },
    removeItem(actionName){
      console.log(actionName)
      var num=parseInt( this.placeArray.indexOf(actionName))*2
      //console.log(num)
      var lineCharts=this.$refs.lineCharts
  
      console.log(lineCharts.chart.series) 
      //console.log('here3')
      for(var tt=0; tt<lineCharts.chart.series.length; tt++){
        
        /*if(lineCharts.chart.series[tt].name===actionName){
          lineCharts.chart.series[tt].remove()
          this.options.series.splice(tt,1)
        }*/
        if(lineCharts.chart.series[tt].name===actionName){
        if(lineCharts.chart.series[tt+1]){
  
        
        if(lineCharts.chart.series[tt].name===lineCharts.chart.series[tt+1].name){
          lineCharts.chart.series[tt].remove()
          lineCharts.chart.series[tt].remove()
          this.options.series.splice(tt,1)
          this.options.series.splice(tt,1)
        }else{
          lineCharts.chart.series[tt].remove()
          this.options.series.splice(tt,1)
        }
        }else{
          console.log('here2')
          lineCharts.chart.series[tt].remove()
          this.options.series.splice(tt,1)
        }
        }
      }
    /*  if(lineCharts.chart.series[num]){
        console.log('here1')
        if(lineCharts.chart.series[num+1]){
  
        
        if(lineCharts.chart.series[num].name===lineCharts.chart.series[num+1].name){
          lineCharts.chart.series[num].remove()
          lineCharts.chart.series[num].remove()
          this.options.series.splice(num,1)
          this.options.series.splice(num,1)
        }else{
          lineCharts.chart.series[num].remove()
          this.options.series.splice(num,1)
        }
        }else{
          console.log('here2')
          lineCharts.chart.series[num].remove()
          this.options.series.splice(num,1)
        }
      
      
      }*/
    },
    dispatchAction(actionName){
      ////console.log('tags',actionName)
      this.show_chart=true
      this.$nextTick(() => { 
        ////console.log(this.place_data_stations)
        var id
        for(var tt=0; tt<this.place_data_stations.length; tt++){
            if(this.place_data_stations[tt].name===actionName){
              id=this.place_data_stations[tt].id
            }
        }
  
       // this.ChartSeries({properties:value})
       ////console.log(this.all_data_predictions)
       ////console.log(id)
       if(this.all_data_predictions){
        loop:
        for(var ww=0; ww<this.all_data_predictions.length; ww++){
         
          if(this.all_data_measures.length){
            
          
          for(var zz=0; zz<this.all_data_measures.length; zz++){
  
             if(this.all_data_predictions[ww].stationid===id){
              var measures
                if(this.all_data_measures[zz].stationid===this.all_data_predictions[ww].stationid){
                  measures=this.all_data_measures[zz]
                  this.ChartSeries(actionName,this.all_data_predictions[ww],measures)
                break loop;
                }
                if(zz===this.all_data_measures.length-1){
                  measures=false
                  this.ChartSeries(actionName,this.all_data_predictions[ww],measures)
                  break loop;
  
                }
                
              //this.ChartSeries(actionName,this.all_data_predictions[zz],this.all_data_measures[zz])
              //break
             }
            }
          }else{
           
              if(this.all_data_predictions[ww].stationid===id){
                this.ChartSeries(actionName,this.all_data_predictions[ww],null)
                break loop;
              }

            }

             if(ww===this.all_data_predictions.length-1){
               alert('No Data')
             }
        }
       }else{
         alert('No Data')
       }
       
        
       // this.ChartSeries({properties:{name:actionName}})
      })
    },
    
    OpenChart(marker){
      this.placeArray=[]
      this.valueMultiSelect=[]
      this.options.series=[]
      var v=this
      //this.modelChartMarker=marker
      v.AddToChart(marker)
     // v.addModelChart(marker)
      
    },
    AddToChart(marker){
      
      this.show_chart=true
      //this.show_calendar=true
      if(window,innerWidth<1024){
        this.map_height='63vh'
        this.show_calendar=true
        setTimeout(() => {this.$refs.myMap.mapObject.invalidateSize(); }, 700);
      
      }else{
        this.show_calendar=true
      }
      //this.placeArray.push(marker.properties.name)
      // ////console.log(this.placeArray)
  
       this.$nextTick(() => { 
         if(!this.placeArray.includes(marker.properties.name)){
          // ////console.log('here')
           if(this.all_data_predictions){
          
            //console.log(this.all_data_predictions)
            //console.log(this.all_data_measures)
            loop:
            for(var ww=0; ww<this.all_data_predictions.length; ww++){
              //console.log(marker.properties.id)
              //console.log(this.all_data_predictions[ww].stationid)
              if(this.all_data_predictions[ww].stationid===marker.properties.id){
                if(this.all_data_measures.length){
                  for(var zz=0; zz<this.all_data_measures.length; zz++){
                
              //console.log(this.all_data_predictions[ww].stationid)
              //console.log(this.all_data_measures[zz].stationid)
              //console.log(marker.properties.id)
             
                
                  var measures
                  //console.log(this.all_data_predictions[ww])
                  //console.log(this.all_data_measures[zz])
                  if(this.all_data_measures[zz].stationid===this.all_data_predictions[ww].stationid){
                    console.log(this.all_data_measures[zz].stationid)
                    console.log(this.all_data_predictions[ww].stationid)
                    measures=this.all_data_measures[zz]
                    this.ChartSeries(marker.properties.name,this.all_data_predictions[ww],measures)
                    break loop;
                  } 
                  if(zz===this.all_data_measures.length-1){
                    measures=false
                    this.ChartSeries(marker.properties.name,this.all_data_predictions[ww],measures)
                    break loop;
    
                  }
                
              }
              }else{
            
              if(this.all_data_predictions[ww].stationid===marker.properties.id){
                this.ChartSeries(marker.properties.name,this.all_data_predictions[ww],null)
                break loop;
              }

            }
            }
              if(ww===this.all_data_predictions.length-1){
                
                alert('No Data')
              }
            
            }
           }else{
         
             alert('No Data')
           }
           
         }
        
       })
       
    },
    getStationData(){
      ////console.log(this.inputValue)
      var v=this
      var date=this.inputValue//.split('-')
      //date=date[0]+'-'+date[2]+'-'+date[1]
      //////console.log(date)
      //this.valueMultiSelect=[]
      this.options.series=[]
      var lineCharts=this.$refs.lineCharts
      /*var lineChartsModel=this.$refs.lineChartsModel
      if(lineChartsModel){
        if(lineChartsModel.chart.series.length){
         // ////console.log(lineCharts)
          lineChartsModel.removeSeries()
        }
      }*/
      if(lineCharts){
        if(lineCharts.chart.series.length){
         // ////console.log(lineCharts)
          lineCharts.removeSeries()
          //lineChartsModel.removeSeries()
        }
      }//measdate:date,variable_Name:this.selected_grid_chart
      var array=[]
      //console.log(date)
      axios.get('https://cityairpp.net/backend/stations_data.php',{params:{measdate:date,variable_Name:this.selected_grid_chart}}).then((response)=>{
        console.log(response.data)
        var re=response.data.predictions
  
        v.all_data_predictions=response.data.predictions
        v.all_data_measures=response.data.measures
        if(!v.all_data_measures){
          v.all_data_measures=[false]
        }
        //console.log(re)
        //console.log(v.all_data_measures)
        //console.log(v.all_data_predictions)
        if(v.all_data_predictions){
          //loop:
          for(var ww=0; ww<re.length; ww++){
          //placeArray
          
  
          loop:
          for(var aa=0; aa<v.place_data_stations.length; aa++){
            if(v.place_data_stations[aa].id===re[ww].stationid){
              if(v.placeArray.includes(v.place_data_stations[aa].name)){
                array.push(v.place_data_stations[aa].name)
                
                if(this.all_data_measures.length){
                for(var zz=0; zz<v.all_data_measures.length; zz++){
  
                var measures
                if(v.all_data_measures[zz].stationid===v.all_data_predictions[ww].stationid){
                  measures=v.all_data_measures[zz]
                  //console.log('here', measures)
                  //console.log('here', v.all_data_predictions[ww])
                  v.ChartSeries(v.place_data_stations[aa].name,v.all_data_predictions[ww],measures)
                  break loop;
                }
                if(zz===this.all_data_measures.length-1){
                  measures=false
                 v.ChartSeries(v.place_data_stations[aa].name,v.all_data_predictions[ww],measures)
                 break loop;
                  
                }
                //v.ChartSeries(marker.properties.name,v.all_data_predictions[ww],measures)
                //break loop;
                
                
              }
            }else{
            
            if(this.all_data_predictions[ww].stationid===v.place_data_stations[aa].id){
              this.ChartSeries(v.place_data_stations[aa].name,v.all_data_predictions[ww],null)
              break loop;
            }

          }
            }
          }
        }
        }
        
        }
        v.placeArray=array
        
        if(v.modelChartMarker.properties.name!==''){
         // console.log('herere')
          v.addModelChart(this.modelChartMarker)
        }
      })
    },
    addModelChart(marker){
      this.show_chart=true
      this.show_calendar=true
      this.$nextTick(() => { 
  
           if(this.all_data_predictions){
  
           loop:
           for(var ww=0; ww<this.all_data_predictions.length; ww++){
           for(var zz=0; zz<this.all_data_measures.length; zz++){
             if(this.all_data_predictions[zz].stationid===marker.properties.id){
               //this.ChartSeries(marker.properties.name,this.all_data_predictions[zz])
               //console.log(this.all_data_predictions[zz].stationid)
               var measures
               if(this.all_data_measures[zz].stationid===this.all_data_predictions[ww].stationid){
                  measures=this.all_data_measures[zz]
                  this.ChartSeriesModel(marker.properties.name,this.all_data_predictions[ww],measures)
                  break loop;
                }
                if(zz===this.all_data_measures.length-1){
                  measures=false
                  this.ChartSeriesModel(marker.properties.name,this.all_data_predictions[ww],measures)
                  break loop;
                }
                
               
             }
            }
             if(ww===this.all_data_predictions.length-1){
               alert('No Data')
             }
           }
           }else{
             alert('No Data')
           }
           
         
        
       })
      
    },
    ChartSeriesModel(dataMeasure,dataPrediction){
      //console.log(dataMeasure)
      if(dataMeasure){
        var measures=dataMeasure.measuresvalues
        measures=measures.split('_')
        measures=measures.map(x => parseFloat(x));
      }
      
      var prediction=dataPrediction.measuresvalues
  
      prediction=prediction.split('_')
      prediction=prediction.map(x => parseFloat(x));
      var lineChartsModel=this.$refs.lineChartsModel
     
      if(lineChartsModel.chart.series.length){
        lineChartsModel.removeSeries()
      }
      //console.log(this.modelChartMarker)
      lineChartsModel.chart.setTitle(null, { text: this.modelChartMarker.properties.name});
      if(dataMeasure){
      lineChartsModel.addSeries({
                name:'Measure',
                color:'#0099ff',
                dashStyle: 'shortdot',
                data: measures
      })
      }
      lineChartsModel.addSeries({
                name:'Prediction',
                color:'#0099ff',
                
                data: prediction
      })
  
    },
    ChartSeries(marker_name,dataPrediction,dataMeasure){
      ////console.log('marker name',marker_name)
      ////console.log('data',data)
      var v=this
  
      
      if(dataMeasure){
        var measures=dataMeasure.measuresvalues
        measures=measures.split('_')
        measures=measures.map(x => parseFloat(x));
      }
      
      var prediction=dataPrediction.measuresvalues
  
      prediction=prediction.split('_')
      prediction=prediction.map(x => parseFloat(x));
      //v.data_marker = t.map(x => parseFloat(x));
  
      ////console.log("axios-req",v.data_marker)
              
         
      var color=this.getRandomColor()
      //var dataSeriesChart 
      var lineCharts=this.$refs.lineCharts
      
      if(this.placeArray.length===0){
        lineCharts.removeSeries()
      }
      
              
              //var id_name=v.polygonSelected.plz_id.split(' ')
              //////console.log(id_name)
              //lineCharts.chart.setTitle(null,{ text: id_name[1] })
        /*for(var qq in this.data_marker){
          if(this.data_marker[qq].name===marker.properties.name){
            dataSeriesChart=this.data_marker[qq].chart_data
          }
        }*/
       // ////console.log(dataSeriesChart)
              lineCharts.addSeries({
                name:marker_name,
                color:color,
                data: prediction//dataSeriesChart
              })
              if(dataMeasure){
              lineCharts.addSeries({
                name:marker_name,
                color:color,
                dashStyle: 'shortdot',
                data: measures//dataSeriesChart
              })
              }
      this.options.series.push({
                name:marker_name,
                color:color,
                data: prediction//dataSeriesChart
              })
      if(!this.placeArray.includes(marker_name)){
        this.placeArray.push(marker_name)//marker.properties.name
      }
      
       
            
    
    },
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      },
    
    hideChart(){
      this.show_calendar=!this.show_calendar
      this.map_height='100vh'
    },
    showCalendar(){
      if(window,innerWidth<1024){
        this.map_height='63vh'
        this.show_calendar=true
        setTimeout(() => {this.$refs.myMap.mapObject.invalidateSize(); }, 700);
      
      }else{
        this.show_calendar=!this.show_calendar
      }
    },
    ChangeSelectedType(){
      this.changeLayer(this.value)
    },
    ChangeSelectedGrid(){
      this.changeLayer(this.value)
      this.selectedLegend=this.legendData[this.selected_grid]
      /*this.placeArray=[]
      //this.valueMultiSelect=[]
      this.options.series=[]
      var lineCharts=this.$refs.lineCharts
      if(lineCharts){
        if(lineCharts.chart.series.length){
         // ////console.log(lineCharts)
          lineCharts.removeSeries()
        }
      }*/
      
    },
    ChangeSelectedGridChart(){
      this.getStationData()
     
    },
    colorIcon(value){
      if(value){
        return 'yellow'
      }else{
        return 'white'
      }
    },
    
      async slider(){
        
      //var all_object=await axios.get("backend/smoke_request.php",{params:{selectedDate:this.valuecalendar}})
     // var all_object=await axios.get("http://smoke.beyond-eocenter.eu/backend/smoke_request.php",{params:{selectedDate:this.valuecalendar}})
      
      //var hour=new Date(1200 * 1000).toISOString().substr(14,5)
      //////console.log(hour)
  
      this.data={}
      for(var i=0; i<1440; i=i+60){
      
      var hour=new Date(i * 1000).toISOString().substr(14,5)
      this.data[i]= hour
      var second=new Date(i * 1000).toISOString().substr(17,2)
      second=parseInt(second)
      //////console.log(hour2)
      //////console.log(typeof(hour2))
      this.optionsPicker.push({value:i.toString(),name:hour})
      var backgroundcolor='black'
      var backgroundcolorText='rgba(255, 0, 0, 0)'
      
      
  
        this.marks[i]={
              label:hour,
              style: {
                width: '8px',
                height: '8px',
                backgroundColor: backgroundcolor,
                transform: 'translate(-2px, -2px)'
              },
              labelStyle: {
                color: 'gray',
                backgroundColor: backgroundcolorText,
                borderRadius: '5px',
                padding: '3px',
              }
              }
      
      }
      this.value='0'
      //this.changeLayer(this.value)
      //////console.log(this.optionsPicker)
      },
    
      play(){
      //////console.log(this.checked1)
      
      if(this.checked1){
       
        loopPLay= setInterval(function(){ 
          //////console.log(this.value)
        var num=parseInt(this.value)+60
         if(num>1425){
          //this.value='0'
          this.checked1=false
          clearInterval(loopPLay);
          
        }else{
        this.value= num.toString()
        this.changeLayer(num)
        }
       }.bind(this), 7500/this.speedPlay);
  
        
      }else{
        clearInterval(loopPLay);
      }
      },
      changeHour(){
      //////console.log(this.value)
      
      this.changeLayer(this.value)
    
      },
      changeLayer(second){
       // ////console.log('here')
        //this.$refs.features.mapObject.closePopup();
        this.layers.layers= 'pm25:pm25_20210612_00'
        
        
        //////console.log(this.$refs.wms_layer[0].mapObject.wmsParams.layers)
        if(this.selected_type==="Hourly"){
          var hour=parseFloat(second)/60
          this.urlImg='./dataPNG/'+this.selected_grid+'/'+this.date_slider+'_'+(parseFloat(hour))+'.png'
  
        }else{
  
          this.urlImg='./dataPNG/'+this.selected_grid+'/'+this.date_slider+'_mean.png'
  
        }
        
        
        //if(hour>9){
         // this.layers.layers='pm25:pm25_20210612_'+hour
          //this.$refs.wms_layer[0].mapObject.wmsParams.layers='pm25:pm25_20210612_'+hour
         // this.$refs.wms_layer[0].mapObject.setParams({layers : 'pm25:pm25_20210612_'+hour})
          ////console.log( this.urlImg)
       // }else{
         // this.layers.layers='pm25:pm25_20210612_0'+hour
         //////console.log(hour)
         //  this.$refs.wms_layer[0].mapObject.setParams({layers : 'pm25:pm25_20210612_0'+hour})
         //  this.urlImg='./dataPNG/'+this.selected_grid+'/'+this.date_slider+'_0'+hour+'.png'
          // ////console.log( this.$refs.wms_layer[0].mapObject.wmsParams.layers)
          //this.$refs.wms_layer[0].mapObject.wmsParams.layers='pm25:pm25_20210612_0'+hour
       // }
       // ////console.log(this.$refs.wms_layer[0].mapObject.wmsParams.layers)
      },
  centerUpdated(center){
        ////console.log(center)
      },
      zoomUpdated(zoom){
        //////console.log(zoom)
        this.zoom_lvl=zoom
      },
      closeNav(){
        document.getElementById("mySidenav").style.width = "0";
        
      },
      openNav(){
         document.getElementById("mySidenav").style.width = "250px";
        
      },
  },
   
    mounted(){
     
      
    },
      
  
  }
  </script>
  
  
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
  <style>
  .btn-outline-light{
    border-color: #f8f9fa00!important;
  }
  .btn-outline-light:hover{
    background: #f8f9fa00!important;
    border-color: #f8f9fa00!important;
  }
  p {
      margin-bottom: 0rem!important;
  }
  
  
  .panel-heading {
      padding: 10px 15px;
      border-bottom: 1px solid transparent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      color: #fff;
      font-family: Trebuchet MS,Helvetica,sans-serif;
      font-style: italic;
  }
  
   .active {
      background: #b80101 !important;
      color: #ffffff !important;
    }
  
  .loader{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background: url('../../public/img/load1.gif') 50% 50% no-repeat rgba(255,193,249,0.5)
  
  }
  
  .col{
      padding-right: 0px; 
      padding-left: 0px;
  }
  .card{
      margin-bottom: 0px;
      height:100%;
      border-radius: .0rem;
  }
  .chard-checkbox{
       background-color: rgba(135, 144, 152,0.8)!important;
  }
  .ol-zoom {
       left: unset;
       right: 8px;
  }
  .vc-popover-content-wrapper[data-v-05016e86]{
      z-index: 1001!important;
  }
  .box2{
    width: 50px; height: 30px;
  }
  .box{
    width: 20px; height: 30px;
  }
  
  p{
    margin-bottom: 0rem;
  }
  .text{
    font-size:12px;
    color: #fff;
  }
  .vl {
    border-left: 4px solid rgb(255, 255, 255);
    height: 5px;
  }
  .leaflet-container {
      font: 20px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  }
  
  
  .leaflet-control-scale-line {
      background: #FFF!important;
  }
  
  .vue-slider-dot-tooltip-show{
    z-index: 1001!important;
  }
  .vue-slider-mark:first-child .vue-slider-mark-step, .vue-slider-mark:last-child .vue-slider-mark-step {
      display: block !important;; 
  }
  .box{
    padding: 10px 10px 15px 40px;
  }
  
  .vl {
    border-left: 4px solid rgb(255, 255, 255);
    height: 4px;
    padding: 0px 46px 0px 0px;
  }
  .text{
    font-size: 15px;
    color: #000000;
    padding: 0px 42px 0px 0px;
  }
  .vue-slider-rail {
      background-color: rgb(250, 250, 250);
  }
  .vue-slider-process {
      background-color: #fff81a;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: transform 1s;
  }
  
  .fade-enter,
  .fade-leave-to {
    transform: translateX(-100%);
  }
  
  .fade-enter-to,
  .fade-leave {
    transform: translateX(0);
  }
  /*
  .leaflet-top, .leaflet-right {
      width: 100%;
  }*/
  
  
  
  
  
  .active {
      background: #b8010100 !important;
      color: #ffff00 !important;
    }
  a{
    color: #c0c0c0 !important;
  }
  a:hover{
    color: #2151aa !important;
  }
  
  .topleft-control{
    box-shadow: 0 0rem 0rem rgb(0 0 0 / 15%) !important;
    background: rgb(153, 164, 153);
    border-radius: 0px 0px 20px 0px;
    box-shadow: 0 0rem 3rem rgb(0 0 0 / 45%) !important;
  }
  .topright-control{
    box-shadow: 0 0rem 0rem rgb(0 0 0 / 15%) !important;
    background: rgb(153, 164, 153);
    border-radius: 0px 0px 0px 20px;
    box-shadow: 0 0rem 3rem rgb(0 0 0 / 45%) !important;
  }
  .leaflet-right .leaflet-control {
      margin-right: 0px!important;
  }
  
  /*
  .leaflet-top, .leaflet-bottom{
    background: #6c757d;
    border-radius: 0px 20px 0px 0px;
    box-shadow: 0 0rem 3rem rgb(0 0 0 / 45%) !important;
  }*/
  .leaflet-left .leaflet-control {
       margin-left: 0px!important; 
  }
  
  .leaflet-top .leaflet-control {
      margin-top: 0px!important; 
  }
  .example-custom-control {
    position: absolute;
    background: #fff;
    padding: 0 1em;
    border: 1px solid #aaa;
    border-radius: 0.1em;
    top:0px;
  }
  .form-group {
       margin-bottom: 0rem!important;
  }
  .row{
    margin-right: 0px!important;
    margin-left: 0px!important;
  }
  
  .vue-scroll-picker-layer .bottom {
   background: linear-gradient(0deg, rgb(72, 72, 72, 1) 10%, rgba(72, 72, 72, 0.908))!important;
  }
  .vue-scroll-picker-layer .top {
      background: linear-gradient(0deg, rgb(72, 72, 72, 0.908) 10%, rgba(72, 72, 72, 1))!important;
  }
  .vue-scroll-picker-list {
      color: white;
  }
  .btn{
    width: 100%!important;
  }
  .btn-warning {
      color: #212529;
      background-color: #ffff00!important;
      border-color: #ffff00!important;
  }
  .vue-slider-dot-tooltip-top{
    z-index: 1005!important;
  }
  .multiselect__tag-icon {
    margin-left: 0px!important;
  }
  .multiselect__tag{
    margin-bottom: 0px!important;
  }
  </style>